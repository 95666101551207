// AddSubAgent.jsx
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogActions,
} from "../dialogstyle";

const localhost = process.env.REACT_APP_API_URL;

const AddSubAgent = ({ open, setOpen, handleFetchData }) => {
  const [owner, setOwner] = useState([]);
  const [shop, setShop] = useState([]);
  const [loader, setLoader] = useState(false);
  const [neterror, setNeterror] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchShop = () => {
    axios
      .get(`${localhost}/shop`)
      .then((res) => {
        console.log(res);
        setShop(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("end");
      });
  };

  const handleFetchShopOwner = () => {
    axios
      .get(`${localhost}/shop-owners`)
      .then((res) => {
        console.log(res);
        setOwner(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("end");
      });
  };

  useEffect(() => {
    handleFetchShop();
    handleFetchShopOwner();
  }, []);

  const [formData, setFormData] = useState({
    shopOwnerId: 0,
    shops: [],
    name: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const handleCheckboxChange = (itemId) => {
    // Check if the item is already selected
    const isSelected = formData.shops.includes(itemId);

    if (isSelected) {
      // If selected, remove it from the array
      setFormData((prevFormData) => ({
        ...prevFormData,
        shops: prevFormData.shops.filter((id) => id !== itemId),
      }));
    } else {
      // If not selected, add it to the array
      setFormData((prevFormData) => ({
        ...prevFormData,
        shops: [...prevFormData.shops, itemId],
      }));
    }
  };

  const [error, setError] = useState({
    shopId: false,
    name: false,
    username: false,
    password: false,
    confirmPassword: false,
  });

  const handleSave = () => {
    console.log(formData);
    setNeterror(false);
    // Validate required fields
    const requiredFields = [
      "shopOwnerId",
      "name",
      "password",
      "confirmPassword",
    ];
    let isValid = true;
    const newError = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });
    // Validate password and confirm password match
    if (formData.password !== formData.confirmPassword) {
      newError.password = true;
      newError.confirmPassword = "password must match";
      isValid = false;
    }

    console.log("savig", isValid);
    console.log("savig", newError);

    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }
    // Add your logic to handle the form data (e.g., send to an API)
    setLoader(true);
    axios
      .post(`${localhost}/sub-agents`, {
        name: formData.name,
        username: formData.name,
        password: formData.password,
        shopOwnerId: formData.shopOwnerId,
        shops: formData.shops,
      })
      .then((res) => {
        console.log(res);
        handleFetchData();
        setFormData({
          shopOwnerId: 0,
          shops: [],
          name: "",
          username: "",
          password: "",
          confirmPassword: "",
        });
        setOpen(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setNeterror(err.response?.data?.error || "Network error");
      })
      .finally(() => {
        setLoader(true);
      });

    console.log(formData);
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  // Filter the shops based on selected shop owners
  const filteredShops = formData.shopOwnerId
    ? shop.filter((shop) => formData.shopOwnerId === shop.shopOwnerId)
    : [];

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Assign sub agent </CustomDialogTitle>
      {neterror && <Alert severity="error">{neterror}</Alert>}
      <DialogContent>
        {/* Form Fields */}
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopId ? "red" : "black",
          }}
        >
          Shop Owner
        </p>
        <Select
          fullWidth
          defaultValue={0}
          // onChange={(e) => setFormData({ ...formData, shopId: e.target.value })}
          onChange={(e) => {
            const selectedShopId = e.target.value;

            // Update formData for shopId
            setFormData({
              ...formData,
              shopOwnerId: selectedShopId,
              shops: [],
            });
          }}
        >
          <MenuItem value={0} disabled>
            Choose shop
          </MenuItem>
          {owner.reverse().map((own) => (
            <MenuItem value={own.id}>{own.name}</MenuItem>
          ))}
        </Select>
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopId ? "red" : "black",
          }}
        >
          Shops
        </p>
        <FormGroup>
          {filteredShops ? (
            filteredShops.map((item) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={formData.shops.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                }
                label={item.name}
              />
            ))
          ) : (
            <p
              style={{
                margin: "0 5px",
                fontSize: "12px",
                color: error.shopId ? "red" : "black",
              }}
            >
              Select shop owner
            </p>
          )}
        </FormGroup>
        <TextField
          margin="normal"
          label="Sub Agent Name"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={error.name}
          onChange={handleChange("name")}
        />
        <TextField
          margin="normal"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={error.name}
          disabled
          onChange={handleChange("username")}
        />

        <TextField
          margin="normal"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.password}
          error={error.password}
          onChange={handleChange("password")}
        />
        <TextField
          margin="normal"
          label="Confirm Password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.confirmPassword}
          error={error.confirmPassword}
          helperText={error.confirmPassword && error.confirmPassword}
          onChange={handleChange("confirmPassword")}
        />
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

export default AddSubAgent;
