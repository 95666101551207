import { useContext, useEffect, useState } from "react";
import {
  Button, Container, Grid, Typography, useTheme, Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Select,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { Edit, Https, Notifications, NotificationsOff } from "@mui/icons-material";
// components
import Label from "../../components/label";
import Scrollbar from "../../components/scrollbar";
import Iconify from "../../components/iconify";
import { AppWidgetSummary } from "../../sections/@dashboard/app";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import AddShopOwner from "../../dialogBoxs/AddShopOwner";
import EditShopOwner from "../../dialogBoxs/EditShopOwner";
import ChangeOwnerPassword from "../../dialogBoxs/ChangeOwnerPassword";
// mock
import USERLIST from "../../_mock/user";
import OWNERLIST from '../../_mock/shopOwner.json';
import { extendCashierLimit, getCashier, getCashierReport, getShop, getShopOwners } from "../../data/fetchShopOwner";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
import AddShop from "../../dialogBoxs/shop/AddShop";
import EditShop from "../../dialogBoxs/shop/EditShop";

import '../../styles/filter.scss'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, selectedShop) {
  // console.log(array);
  const stabilizedThis = array ? array?.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  if (selectedShop) {
    return filter(
      array,
      (_user) => _user?.shop.id === selectedShop
    )
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  // { id: "id", label: "Id", alignRight: false },
  { id: "shop", label: "Shop", alignRight: false },
  { id: "name", label: "Cashier", alignRight: false },
  { id: "tickets", label: "Tickets", alignRight: false },
  { id: "stakes", label: "Stakes", alignRight: false },
  { id: "payout", label: "Payout amount", alignRight: false },
  { id: "unclamed", label: "Unclamed amount", alignRight: false },
  { id: "revoked", label: "Revoked amount", alignRight: false },
  { id: "ggr", label: "GGR", alignRight: false },
  { id: "netBalance", label: "Net balance", alignRight: false },
  { id: "cashierLimit", label: "Casher limit", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
  { id: "", label: "Action", alignRight: false },
];

export default function CashierLimit(params) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openPasswordId, setOpenPasswordId] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditData, setOpenEditData] = useState({});

  const { data, dispatch } = useContext(CampaignContext);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("netBalance");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [owner, setOwner] = useState([]);
  const [selectedShop, setSelectedShop] = useState(0);

  const [shop, setShop] = useState([]);
  const handleFetchShop = () => {
    getShop(shop, setShop, dispatch, setLoader);
  }

  const handleOpenPassword = (id) => {
    setOpenPasswordId(id);
    setOpenPassword(true);
  };

  const handleOpen = () => {
    console.log('handle');
    setOpen(true);
  };

  const handleOpenEdit = () => {
    console.log('handle');
    setOpenEdit(true);
  };
  const handleFetchData = () => {
    getCashierReport(owner, setOwner, dispatch, setLoader);
  }
  useEffect(() => {
    handleFetchData();
    handleFetchShop();
  }, []);


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = owner.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleEdit = (row) => {
    // console.log('row', row);
    setOpenEditData({
      id: row.id,
      name: row.name,
      username: row.username,
      cashierLimit: row.cashierLimit,
      location: row.location,
      maxStake: row.maxStake,
      minStake: row.minStake,
      owner: row.owner,
      shopOwnerId: row.shopOwnerId,
      status: row.status
    })
    setOpenEdit(true);
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - owner.length) : 0;

  const filteredUsers = applySortFilter(
    owner,
    getComparator(order, orderBy),
    filterName,
    selectedShop
  );
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Cahsier Limit
          </Typography>
        </Stack>

        <Card sx={{ margin: '1rem 0' }}>
          <Grid container spacing={3} className="fillterGridLeft">
            <Grid item xs={9} sm={9} xl={6}>
              {/* <Label>Shop</Label> */}
              <Select fullWidth defaultValue={0} value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}>
                <MenuItem value={0}>All shop</MenuItem>
                {
                  shop.map((item, index) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid item xs={3} sm={3} xl={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {
                loader ? (
                  <Button variant="contained" fullWidth style={{ height: '42px', padding: '10px', background: '#7FD858' }}><CircularProgress sx={{ color: 'white' }} /></Button>
                ) : (
                  <Button variant="contained" fullWidth style={{ height: '42px', background: '#7FD858' }}>Load Cashier</Button>
                )
              }
            </Grid>
            {/* <Grid item></Grid> */}
          </Grid>
        </Card>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={owner.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {
                    // loader ? (<div style={{ height: '50px' }}><CircularProgress /></div>) :
                    filteredUsers && filteredUsers
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        // console.log(row);
                        const {
                          id,
                          shopId,
                          name,
                          cashierLimit,
                          shop,
                          username,
                          status,
                          slips
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={id}
                          // tabIndex={-1}
                          // role="checkbox"
                          // selected={selectedUser}
                          >
                            <TableCell>
                              {(index + 1)}
                            </TableCell>

                            <TableCell align="left">{shop.name}</TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{slips[0]?.tickets || 0}</TableCell>
                            <TableCell align="left">{slips[0]?.stake || 0.00} birr</TableCell>
                            <TableCell align="left">{slips[0]?.payout || 0.00} birr</TableCell>
                            <TableCell align="left">{slips[0]?.unclaimed || 0.00} birr</TableCell>
                            <TableCell align="left">{slips[0]?.revoked || 0}</TableCell>
                            <TableCell align="left">{(parseInt(slips[0]?.stake, 10) - parseInt(slips[0]?.payout, 10) - parseInt(slips[0]?.unclaimed, 10)) || 0} birr</TableCell>
                            <TableCell align="left">{(parseInt(slips[0]?.stake, 10) - parseInt(slips[0]?.payout, 10)) || 0} birr</TableCell>
                            <TableCell align="left">{shop?.cashierLimit || 0.00} birr</TableCell>
                            {/* <TableCell align="left">{cashierLimit || 0.00} birr</TableCell> */}


                            <TableCell align="left">
                              {/* <Grid container spacing={3} row rowGap={3}>
                                  <Grid item xs={6}> */}
                              <Button variant="contained" color="primary" fullWidth onClick={() => extendCashierLimit(dispatch, setLoader, id, handleFetchData)}>withdraw</Button>
                              {/* </Grid>
                                </Grid> */}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  }
                  {(filteredUsers.length <= 0 && !loader) && (
                    <TableRow
                      style={{
                        height: '40px',
                      }}
                    >
                      <TableCell
                        colSpan={8}
                        align="center"
                        style={{
                          height: "paddingHeight",
                          padding: "40px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        No Shops Found
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={owner.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <AddShop open={open} setOpen={setOpen} handleFetchData={handleFetchData} />
    </>
  );
};
