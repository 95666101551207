// AddCashier.jsx
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Alert, MenuItem, Select } from "@mui/material";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogActions,
} from "../dialogstyle";

const localhost = process.env.REACT_APP_API_URL;

const AddCashier = ({ open, setOpen, handleFetchData }) => {
  const [owner, setOwner] = useState([]);
  const [loader, setLoader] = useState(false);
  const [neterror, setNeterror] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchShop = () => {
    axios
      .get(`${localhost}/shop`)
      .then((res) => {
        console.log(res);
        setOwner(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("end");
      });
  };

  useEffect(() => {
    handleFetchShop();
  }, []);

  const [formData, setFormData] = useState({
    shopId: 0,
    cashNo: 1,
    name: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    shopId: false,
    name: false,
    username: false,
    password: false,
    confirmPassword: false,
  });

  const handleSave = () => {
    console.log(formData);
    // Validate required fields
    const requiredFields = [
      "shopId",
      // "name",
      "username",
      // "password",
      // "confirmPassword",
    ];
    let isValid = true;
    const newError = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });
    // Validate password and confirm password match
    // if (formData.password !== formData.confirmPassword) {
    //   newError.password = true;
    //   newError.confirmPassword = "password must match";
    //   isValid = false;
    // }

    console.log("savig", isValid);
    console.log("savig", newError);

    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }
    // Add your logic to handle the form data (e.g., send to an API)
    setLoader(true);
    axios
      .post(`${localhost}/cashiers/addCashier`, {
        shopId: formData.shopId,
        username: formData.username,
        cashNo: parseInt(formData.cashNo, 10),
      })
      .then((res) => {
        console.log(res);
        handleFetchData();
        setFormData({
          shopId: 0,
          name: "",
          username: "",
          password: "",
        });
        setOpen(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setNeterror(err.response?.data?.error || "Network error");
      })
      .finally(() => {
        setLoader(true);
      });

    console.log(formData);
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Add Cashier </CustomDialogTitle>
      {neterror && <Alert severity="error">{neterror}</Alert>}
      <DialogContent>
        {/* Form Fields */}
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopId ? "red" : "black",
          }}
        >
          Shop
        </p>
        <Select
          fullWidth
          defaultValue={0}
          // onChange={(e) => setFormData({ ...formData, shopId: e.target.value })}
          onChange={(e) => {
            const selectedShopId = e.target.value;
            const selectedShop = owner.find((own) => own.id === selectedShopId);

            // Update formData for shopId
            setFormData({
              ...formData,
              shopId: selectedShopId,
              username: selectedShop.username,
            });
          }}
        >
          <MenuItem value={0} disabled>
            Choose shop
          </MenuItem>
          {owner.reverse().map((own) => (
            <MenuItem value={own.id}>{own.name}</MenuItem>
          ))}
        </Select>
        <TextField
          margin="normal"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.username}
          error={error.username}
          disabled
          onChange={handleChange("username")}
        />
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
          }}
        >
          Number of cashier
        </p>
        <Select
          fullWidth
          defaultValue={1}
          value={formData.cashNo}
          onChange={handleChange("cashNo")}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
        </Select>
        {/* <TextField
          margin="normal"
          label="Name"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={error.name}
          onChange={handleChange("name")}
        />
        
        <TextField
          margin="normal"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.password}
          error={error.password}
          onChange={handleChange("password")}
        />
        <TextField
          margin="normal"
          label="Confirm Password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.confirmPassword}
          error={error.confirmPassword}
          helperText={error.confirmPassword && error.confirmPassword}
          onChange={handleChange("confirmPassword")}
        /> */}
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

export default AddCashier;
