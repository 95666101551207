import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Box, Tabs, Tab } from "@mui/material";
import { Search } from "@mui/icons-material";
import GameResult from "./gameResult";
import SlipDetail from "./SlipDetail";
import SlipReport from "./SlipReport";
import ManageSubagent from "../Manage/ManageSubAgent";
// components

// ----------------------------------------------------------------------
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function BackOfficeUser() {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Back office user </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Back office user
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="back office" TabIndicatorProps={{
            style: {
              backgroundColor: '#5db438',
              color: "#5db438"
            },
          }}  >
            <Tab style={{ color: value === 0 ? '#5db438' : '#858585', fontSize: '16px' }} label="Sub Agent" {...a11yProps(0)} />
            <Tab style={{ color: value === 1 ? '#5db438' : '#858585', fontSize: '16px' }} label="It support" {...a11yProps(1)} />
            <Tab style={{ color: value === 2 ? '#5db438' : '#858585', fontSize: '16px' }} label="Finnance" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ManageSubagent />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Comming soon
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Comming soon
        </CustomTabPanel>
      </Container>
    </>
  );
}
