import axios from "axios";

const localhost = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = false;
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

export const getShopOwners = (state, setState, dispatch, setLoader) => {
  console.log("fetching shop owner");
  setLoader(true);
  axios
    .get(
      `${localhost}/shop-owners`,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const getShop = (state, setState, dispatch, setLoader) => {
  console.log("fetching shop");
  setLoader(true);
  axios
    .get(
      `${localhost}/shop`,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const getSubagent = (state, setState, dispatch, setLoader) => {
  // console.log("fetching shop");
  setLoader(true);
  axios
    .get(
      `${localhost}/sub-agents`,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const getCashier = (state, setState, dispatch, setLoader) => {
  console.log("fetching cashier");
  setLoader(true);
  axios
    .get(
      `${localhost}/cashiers`,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const getCashierReport = (state, setState, dispatch, setLoader) => {
  console.log("get Cashier Report");
  setLoader(true);
  axios
    .get(
      `${localhost}/slip/generateDetailCashierReport`,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const extendCashierLimit = (dispatch, setLoader, id, handleFetchData) => {
  console.log("extend Cashier Limit", id);
  setLoader(true);
  axios
    .post(
      `${localhost}/cashiers/extendCashierLimit?cashId=${id}`,
      config
    )
    .then((res) => {
      console.log(res);
      handleFetchData();
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
      return true;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const searchSlip = (state, setState, dispatch, setLoader, number) => {
  console.log("search Slip");
  setLoader(true);
  axios
    .get(
      `${localhost}/slip/getByGameNumber?code=${number}`,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const searchGame = (state, setState, dispatch, setLoader, number, date, gameType, selectedShop) => {
  console.log("search Game");
  if (!selectedShop) {
    dispatch({
      type: "setShowSnackBar",
      payload: {
        show: true,
        message: "Select a shop!",
      },
    });
  }
  let url = `${localhost}/game/searchGame?shopId=${selectedShop}&`
  if (gameType) url += `gameType=${gameType}&`
  if (date) url += `date=${date}&`
  if (number) url += `eventId=${number}`
  setLoader(true);

  axios
    .get(
      // `${localhost}/game/searchGame?gameType=${gameType}&date=${date || ''}&eventId=${number || ' '}`,
      url,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "setShowSnackBar",
        payload: {
          show: true,
          message:
            err?.response?.data?.message || err?.message || "Network Error",
        },
      });
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const getShopReport = (state, setState, dispatch, setLoader, filterData) => {
  console.log("search Game", filterData);
  const url = `${localhost}/dailyReport/generateShopReport`
  // if (filterData.gameType) url += `gameType=${gameType}&`
  // if (filterData.date) url += `date=${date}&`
  // if (filterData.number) url += `eventId=${number}`

  setLoader(true);
  axios
    .post(
      `${localhost}/dailyReport/generateShopReport`, {
      ...(filterData.shop && { shopId: filterData.shop }),
      ...(filterData.shopOwner && { shopOwnerId: filterData.shopOwner }),
      ...(filterData.from && { startDate: formatDate(filterData.from) }),
      ...(filterData.to && { endDate: formatDate(filterData.to) }),
    },
      // url,
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 404) {
        setState([])
      } else {

        dispatch({
          type: "setShowSnackBar",
          payload: {
            show: true,
            message:
              err?.response?.data?.message || err?.message || "Network Error",
          },
        });
      }
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const getCashierRetailReport = (state, setState, dispatch, setLoader, filterData) => {
  console.log("cahsier retal Game", filterData);
  console.log("cahsier retal Game", filterData.from);
  setLoader(true);
  const url = `${localhost}/dailyReport/generateCashierReport`
  // if (filterData.gameType) url += `gameType=${gameType}&`
  // if (filterData.date) url += `date=${date}&`
  // if (filterData.number) url += `eventId=${number}`

  axios
    .post(
      `${localhost}/dailyReport/generateCashierReport`, {
      ...(filterData.gameType && { gameType: filterData.gameType }),
      ...(filterData.shop && { shopId: filterData.shop }),
      ...(filterData.shopOwner && { shopOwnerId: filterData.shopOwner }),
      ...(filterData.from && { startDate: formatDate(filterData.from) }),
      ...(filterData.to && { endDate: formatDate(filterData.to) }),
    },
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 404) {
        setState([])
      } else {

        dispatch({
          type: "setShowSnackBar",
          payload: {
            show: true,
            message:
              err?.response?.data?.message || err?.message || "Network Error",
          },
        });
      }
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const getSlipReport = (state, setState, dispatch, setLoader, filterData) => {
  console.log("search Game", filterData);
  setLoader(true);
  // if (filterData.date) url += `date=${date}&`
  // if (filterData.number) url += `eventId=${number}`
  console.log('fil:', filterData);
  axios
    .post(
      `${localhost}/slip/getSlip`, {
      ...(filterData.gameType && { gameType: filterData.gameType }),
      ...(filterData.shop && { shopId: filterData.shop }),
      ...(filterData.status && { status: filterData.status }),
      ...(filterData.from && { startDate: formatDate(filterData.from) }),
      ...(filterData.to && { endDate: formatDate(filterData.to) }),
      ...(filterData.number && { eventId: filterData.number })
    },
      config
    )
    .then((res) => {
      console.log(res);
      setState(res.data)
      // dispatch({
      //   type: "setCampaigns",
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 404) {
        setState([])
      } else {
        dispatch({
          type: "setShowSnackBar",
          payload: {
            show: true,
            message:
              err?.response?.data?.message || err?.message || "Network Error",
          },
        });
      }
    })
    .finally(() => {
      console.log("end");
      setLoader(false);
    });
};

export const handleChangeCashierStatus = (state, setState, dispatch, setLoader, row) => {
  // setLoader(true)
  axios
    .put(`${localhost}/cashiers/${row.id}`, {
      status: !row.status,
    })
    .then((res) => {
      console.log(res);
      getCashier(state, setState, dispatch, setLoader);
    })
    .catch((err) => {
      console.log(err);
    });
}

function formatDate(date) {
  date = new Date(date);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export const fetchShopStat = (setState) => {
  axios
    .get(`${localhost}/dailyReport/generateShopCount`)
    .then((res) => {
      console.log(res);
      setState(res.data);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      console.log("end");
    });
}

export const fetchShop = (setState) => {
  axios
    .get(`${localhost}/shop`)
    .then((res) => {
      console.log(res);
      setState(res.data);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      console.log("end");
    });
}

export const fetchShopOwner = (setState) => {
  axios
    .get(`${localhost}/shop-owners`)
    .then((res) => {
      console.log(res);
      setState(res.data);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      console.log("end");
    });
};