// EditSubAgent.jsx
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { Delete, RemoveCircleOutline } from "@mui/icons-material";
import { CustomDialogActions, CustomDialogTitle } from "../dialogstyle";

const localhost = process.env.REACT_APP_API_URL;
const EditSubAgent = ({ open, setOpen, editItem, handleFetchData }) => {
  const [owner, setOwner] = useState([]);
  const [shop, setShop] = useState([]);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchShop = () => {
    setLoader(true);
    axios
      .get(`${localhost}/shop`)
      .then((res) => {
        console.log(res);
        setShop(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
        console.log("end");
      });
  };

  const handleFetchShopOwner = () => {
    axios
      .get(`${localhost}/shop-owners`)
      .then((res) => {
        console.log(res);
        setOwner(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("end");
      });
  };

  useEffect(() => {
    handleFetchShop();
    handleFetchShopOwner();
  }, []);

  useEffect(() => {
    console.log("effect");
    setFormData(editItem);
  }, []);

  const handleCheckboxChange = (itemId) => {
    // Check if the item is already selected
    const isSelected = formData.shops.includes(itemId);

    if (isSelected) {
      // If selected, remove it from the array
      setFormData((prevFormData) => ({
        ...prevFormData,
        shops: prevFormData.shops.filter((id) => id !== itemId),
      }));
    } else {
      // If not selected, add it to the array
      setFormData((prevFormData) => ({
        ...prevFormData,
        shops: [...prevFormData.shops, itemId],
      }));
    }
  };

  const [formData, setFormData] = useState({});
  console.log("initail", editItem);
  console.log("form", formData);

  const [error, setError] = useState({
    shopOwnerId: false,
    name: false,
    status: false,
  });

  const handleSave = () => {
    // Validate required fields
    const requiredFields = ["name", "username"];
    let isValid = true;
    const newError = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });

    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }

    // Add your logic to handle the form data (e.g., send to an API)
    setLoader(true);
    axios
      .put(`${localhost}/sub-agents/${editItem.id}`, {
        name: formData.name,
        shops: formData.shops,
        status: formData.status,
        username: formData.username,
      })
      .then((res) => {
        console.log(res);
        handleFetchData();
        setOpen(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });

    console.log(formData);
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  // Filter the shops based on selected shop owners
  const selectedShopIds = formData.sub_agent_shops
    ? formData.sub_agent_shops.map((shop) => shop.shopId)
    : [];
  const filteredShops = formData.shopOwnerId
    ? shop.filter(
        (shop) =>
          !selectedShopIds.includes(shop.id) &&
          formData.shopOwnerId === shop.shopOwnerId
      )
    : // ? shop.filter((shop) => formData.shopOwnerId === shop.shopOwnerId)
      [];

  const handleDeleteShop = (id) => {
    setLoader(true);
    axios
      .delete(`${localhost}/sub-agent-shops/${id}`)
      .then((res) => {
        console.log(res);
        handleFetchData();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("end");
        setLoader(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Assign sub agent </CustomDialogTitle>
      {/* {neterror && <Alert severity="error">{neterror}</Alert>} */}
      <DialogContent>
        {/* Form Fields */}
        <FormGroup>
          <p
            style={{
              margin: "0 5px",
              fontSize: "12px",
              color: error.shopId ? "red" : "black",
            }}
          >
            Shop Owner
          </p>
          <Select
            fullWidth
            defaultValue={editItem.shopOwnerId && editItem.shopOwnerId}
            value={editItem.shopOwnerId && editItem.shopOwnerId}
            // onChange={(e) => setFormData({ ...formData, shopId: e.target.value })}
            onChange={(e) => {
              const selectedShopId = e.target.value;

              // Update formData for shopId
              setFormData({
                ...formData,
                shopOwnerId: selectedShopId,
                shops: [],
              });
            }}
            disabled
          >
            <MenuItem value={0} disabled>
              Choose shop
            </MenuItem>
            {owner.reverse().map((own) => (
              <MenuItem value={own.id}>{own.name}</MenuItem>
            ))}
          </Select>
        </FormGroup>
        <TextField
          margin="normal"
          label="Sub Agent Name"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={error.name}
          onChange={handleChange("name")}
        />
        <TextField
          margin="normal"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={error.name}
          onChange={handleChange("username")}
        />
        <FormGroup>
          <p style={{ margin: "0 5px" }}>status</p>
          <Select
            fullWidth
            defaultValue={"active"}
            value={formData.status ? 1 : 0}
            onChange={(e) => {
              console.log("value", e.target.value);
              setFormData({ ...formData, status: e.target.value });
            }}
          >
            <MenuItem value={1}>active</MenuItem>
            <MenuItem value={0}>inactive</MenuItem>
          </Select>
        </FormGroup>
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopId ? "red" : "black",
          }}
        >
          Active Shops
        </p>
        <FormGroup>
          <List>
            {formData?.sub_agent_shops ? (
              formData?.sub_agent_shops.map((item) => {
                console.log("item", item);
                return (
                  <>
                    {/* <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={formData?.shops.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  }
                  label={item.shop.name}
                /> */}
                    {
                      <ListItem
                        sx={{
                          border: "1px solid #82ba6a",
                          borderRadius: "5px",
                          marginBottom: "5px",
                        }}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            {/* <Delete onClick={() => handleDeleteShop(item.id)} /> */}
                            <RemoveCircleOutline
                              sx={{ color: "red" }}
                              onClick={() => handleDeleteShop(item.id)}
                            />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={item.shop.name} />
                      </ListItem>
                    }
                  </>
                );
              })
            ) : (
              <p
                style={{
                  margin: "0 5px",
                  fontSize: "12px",
                  color: error.shopId ? "red" : "black",
                }}
              >
                Select shop owner
              </p>
            )}
          </List>
        </FormGroup>
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopId ? "red" : "black",
          }}
        >
          Shops
        </p>
        <FormGroup>
          {filteredShops ? (
            filteredShops.map((item) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={formData.shops.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                }
                label={item.name}
              />
            ))
          ) : (
            <p
              style={{
                margin: "0 5px",
                fontSize: "12px",
                color: error.shopId ? "red" : "black",
              }}
            >
              Select shop owner
            </p>
          )}
        </FormGroup>
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Cancel
        </Button>
        {loader ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <Button onClick={handleSave} variant="outlined" color="primary">
            Save
          </Button>
        )}
      </CustomDialogActions>
    </Dialog>
  );
};

export default EditSubAgent;
