import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Label } from "reactstrap";
import axios from "axios";
import { styled } from "@mui/styles";
// components
import Iconify from "../../../components/iconify";

const localhost = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = false;
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

// ----------------------------------------------------------------------
const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor
    }
  }
}));

export default function LoginForm() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inputForm, setInputForm] = useState({
    username: "",
    password: "",
    success: "",
    error: "",
  });

  const handleClick = () => {
    setError(false);
    if (inputForm.username !== "" && inputForm.password !== "") {
      setLoader(true);
      axios.post(`${localhost}/admin/login`, {
        "username": inputForm.username,
        "password": inputForm.password
      }).then((res) => {
        console.log(res);
        localStorage.setItem("access_token", res.data.accessToken);
        localStorage.setItem("refresh_token", res.data.refreshToken);
        // localStorage.setItem("shopOwnerId", res.data.id);
        // localStorage.setItem("shopOwner", JSON.stringify(res.data.shopOwner));
        navigate("/dashboard", { replace: true });
      }).catch((err) => {
        console.log(err);
        setError(err.response?.data?.error || "Network Error")
      }).finally(() => {
        setLoader(false);
      });
    } else {
      setError("Fill all the fields")
    }
  };

  return (
    <>
      <Stack spacing={1}>
        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 0, color: 'white', textAlign: 'center', width: '100%', alignItems: 'center' }}
        > */}
        {
          error && <Alert fullWidth variant="filled" severity="error">
            {error}
          </Alert>
          // error && <p>{error}</p>
        }
        {/* </Stack> */}
        <FormControl>
          <Label style={{ color: 'white', marginBottom: '5px' }}>Username</Label>
          <CssTextField
            focusColor='#7FD858'
            name="username"
            // label="Username"
            value={inputForm.username}
            error={error}
            onChange={(e) => {
              setInputForm({ ...inputForm, username: e.target.value });
            }}
            inputProps={{
              style: { color: 'white' },
            }}
          // style={{ backgroundColor: '#133337', color: 'white' }}
          // sx={{ color: "white" }}
          />
        </FormControl>

        <FormControl>
          <Label style={{ color: 'white', marginBottom: '5px' }}>Password</Label>
          <CssTextField
            focusColor='#7FD858'
            name="password"
            // label="Password"
            type={showPassword ? "text" : "password"}
            value={inputForm.password}
            error={error}
            // style={{ backgroundColor: '#133337', color: 'white' }}
            onChange={(e) => {
              setInputForm({ ...inputForm, password: e.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                color: 'white',
              }
            }}
          />
        </FormControl>
      </Stack>


      {/* <Checkbox name="remember" label="Remember me" />
       <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}

      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{
          backgroundColor: "#7FD858", '&:hover': {
            backgroundColor: '#5db438', // Change the color for hover state
          },
          marginTop: '10px'
        }}
        onClick={handleClick}
      >
        {
          loader ? <CircularProgress sx={{ color: 'white', padding: '5px' }} /> : "Login"
        }
      </Button>

      <p style={{ color: '#5db438', fontSize: '14px', textAlign: 'center' }}>Rock Technologies. All right reserved.</p>
    </>
  );
}
