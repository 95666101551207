import * as XLSX from "xlsx";

// Your dynamic data

const exportDataToExcel = (name, data, cash) => {
  const header = [
    "ShopOwner",
    "Shop",
    "Tickets",
    "TotalBet",
    "Payout",
    "NetBalance",
  ]
  if (cash) {
    header.splice(2, 0, "Cashier"); // Insert Cashier value after Shop
  }
  const dynamicData = [
    header
    ,
    ...data?.map((row) => {
      const rows = [
        row.ShopOwner,
        row.Shop,
        row.Tickets,
        row.TotalBet,
        row.Payout,
        row.NetBalance,
      ]
      if (cash) {
        rows.splice(2, 0, row.Cashier); // Insert Cashier value after Shop
      }
      return rows
    }),
    // Add more rows as needed
  ];
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(dynamicData);

  // Apply formatting to the sheet (make the first row bold)
  ws.A1.s = { font: { bold: true } };
  ws.B1.s = { font: { bold: true } };
  ws.C1.s = { font: { bold: true } };
  ws.D1.s = { font: { bold: true } };
  ws.E1.s = { font: { bold: true } };
  ws.F1.s = { font: { bold: true } };

  // Set column widths
  const colWidths = dynamicData[0].map((col, i) => {
    return { wch: col.toString().length + 4 };
  });
  ws["!cols"] = colWidths;

  // Create a workbook
  XLSX.utils.book_append_sheet(wb, ws, "Report", { header: 1 });

  // Save the workbook to a file
  XLSX.writeFile(wb, `${name}.xlsx`, { bookSST: true, header: 1 });
};

export default exportDataToExcel;

// Create a worksheet
