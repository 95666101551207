// AddShop.jsx
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert, CircularProgress, MenuItem, Select } from "@mui/material";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogActions,
} from "../dialogstyle";
import locationlist from "./location.json";

import "react-toastify/dist/ReactToastify.css";

const localhost = process.env.REACT_APP_API_URL;

const AddShop = ({ open, setOpen, handleFetchData }) => {
  const [owner, setOwner] = useState([]);
  const [neterror, setNetError] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchShopOwner = () => {
    axios
      .get(`${localhost}/shop-owners`)
      .then((res) => {
        console.log(res);
        setOwner(res.data);
      })
      .catch((err) => {
        console.log(err);
        // dispatch({
        //   type: "setShowSnackBar",
        //   payload: {
        //     show: true,
        //     message:
        //       err?.response?.data?.message || err?.message || "Network Error",
        //   },
        // });
      })
      .finally(() => {
        console.log("end");
      });
  };

  useEffect(() => {
    handleFetchShopOwner();
  }, []);

  const [formData, setFormData] = useState({
    shopOwnerId: 0,
    name: "",
    username: "",
    location: "Addis Ababa",
    cashNo: 1,
    status: "",
  });

  const [error, setError] = useState({
    shopOwnerId: false,
    name: false,
    location: false,
    status: false,
  });

  const handleSave = () => {
    // Validate required fields
    const requiredFields = ["shopOwnerId", "name", "location", "username"];
    let isValid = true;
    setNetError(false);
    const newError = {};
    // console.log(formData.shopOwnerId);
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });

    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }
    setLoader(true);
    // Add your logic to handle the form data (e.g., send to an API)
    axios
      .post(`${localhost}/shop`, {
        name: formData.name,
        username: formData.username,
        cashNo: formData.cashNo,
        shopOwnerId: formData.shopOwnerId,
        location: formData.location,
        // status: formData.status,
      })
      .then((res) => {
        console.log(res);
        toast.success("Added successfully...!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleFetchData();
        setFormData({
          shopOwnerId: 0,
          name: "",
          location: "",
          status: "",
        });
        setOpen(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setNetError(err.response?.data?.error || "Network error");
      })
      .finally(() => {
        setLoader(false);
      });

    console.log(formData);
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Create Shop and cashier</CustomDialogTitle>
      {neterror && <Alert severity="error">{neterror}</Alert>}
      <DialogContent>
        {/* Form Fields */}
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopOwnerId ? "red" : "black",
          }}
        >
          Shop Owner
        </p>
        <Select
          fullWidth
          defaultValue={0}
          onChange={handleChange("shopOwnerId")}
        >
          <MenuItem value={0} disabled>
            Choose Owner
          </MenuItem>
          {owner.reverse().map((own) => (
            <MenuItem value={own.id}>{own.name}</MenuItem>
          ))}
        </Select>
        <TextField
          margin="normal"
          label="Shop Name"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={error.name}
          onChange={handleChange("name")}
        />
        <TextField
          margin="normal"
          label="Username"
          fullWidth
          variant="outlined"
          value={formData.username}
          error={error.username}
          onChange={handleChange("username")}
        />
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: "rgba(0,0,0,0.5)",
          }}
        >
          Username will be used at the start of cashiers name.{" "}
          <span style={{ fontWeight: "bolder" }}>
            ({formData.username ? formData.username : "username"}.c1)
          </span>
        </p>
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopOwnerId ? "red" : "black",
          }}
        >
          Location
        </p>
        <Select
          fullWidth
          value={formData.location}
          onChange={handleChange("location")}
          error={error.location}
          defaultValue={"Addis Ababa"}
        >
          {locationlist.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
          }}
        >
          Number of cashier
        </p>
        <Select
          fullWidth
          defaultValue={1}
          value={formData.cashNo}
          onChange={handleChange("cashNo")}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
        </Select>
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: "rgba(0,0,0,0.5)",
          }}
        >
          the system will automatically create the cashiers
        </p>
        {/* <TextField
          margin="normal"
          label="Cashier Password"
          fullWidth
          variant="outlined"
          // value={formData.name}
          // error={error.name}
          // onChange={handleChange("name")}
        /> */}
        {/* <p style={{ margin: "0 5px", fontSize: "12px" }}>status</p>
        <Select
          fullWidth
          defaultValue={"active"}
          onChange={handleChange("status")}
        >
          <MenuItem value={1}>active</MenuItem>
          <MenuItem value={0}>inactive</MenuItem>
        </Select> */}
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {loader ? (
          <Button color="primary">
            <CircularProgress />
          </Button>
        ) : (
          <Button onClick={handleSave} color="primary">
            Create
          </Button>
        )}
      </CustomDialogActions>
    </Dialog>
  );
};

export default AddShop;
